<template>
	<ClientOnly fallback-tag="iframe" fallback="Carregando mapa...">
		<iframe class="w-full h-[250px] lg:h-[350px] rounded-box" style="border: 0" loading="lazy" allowfullscreen referrerpolicy="no-referrer-when-downgrade" :src="urlEmbed"> </iframe>
	</ClientOnly>
</template>

<script setup lang="ts">
const apiKey: string = 'AIzaSyCKm_gU7BYn-T0BakHmTJ1EzIbF2B5EEAE'
const query: string = '-16.717355728149414,-49.280799865722656' //https://maps.google.com/?q=-16.7069263458252,-49.2868804931641
const urlEmbed: string = `https://google.com/maps/embed/v1/place?key=${apiKey}&q=${query}`
</script>
